import React, { useState, useEffect, useRef } from 'react';
import { Grid, Container, Card, CardContent, CardMedia } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import { H1, H2, H5 } from 'components/Typography';
import { Modal } from 'react-responsive-modal';
import { observer } from 'mobx-react-lite';
import { Icon } from '@ailibs/feather-react-ts';
import Menu from 'pages/client/homePage/Menu';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import { useStore } from 'app/stores/store';
import { useNavigate } from 'react-router-dom';
import agent from 'app/api/agent';
import { Helmet } from 'react-helmet';
import { BrowserView, MobileOnlyView } from 'react-device-detect';
import CssBaseline from '@mui/material/CssBaseline';
import imageHelper from 'app/utils/imageHelper';
import '../app/styles.css';

interface Props {
  children: React.ReactNode;
}

const AppClientLayout = (props: Props) => {
  const [openFirst, setOpenFirst] = useState(false);
  const cookieRef = useRef<HTMLDivElement>();
  const navigate = useNavigate();
  const { t } = useTranslation(['common']);
  const {
    currentTenantStore: { currentTenants, getLogo },
    contactStore: { contacts },
    privacyPolicyStore: { privacyPolicies, loadPrivacyPolicy },
    colorStore
  } = useStore();

  const [cookie, setCookie] = useState('');
  const [logoPath, setLogoPath] = useState('');

  const modalCloseIcon = (
    <Icon name="x" size="30px" color={colorStore.colorC} />
  );

  useEffect(() => {
    setOpenFirst(false);
  }, [props.children]);

  useEffect(() => {
    const load = async () => {
      const result = await agent.PrivacyPolicies.list(); //obtaining this from store did not work for some reason, fetching via agent directly instead.
      setCookie(t(result.data.cookiesContent)!);
      const logo = await agent.CurrentTenants.logo();
      setLogoPath(logo.data);
    };

    load();
  }, [t]);

  useEffect(() => {
    if (!cookieRef.current) {
      console.warn('Cookie ref not found!');
      return;
    }

    cookieRef.current.innerHTML = t(privacyPolicies!.cookiesContent);
  }, [cookie]);

  return (
    <MuiThemeProvider theme={colorStore.theme}>
      <ThemeProvider theme={colorStore.theme}>
        <CssBaseline />
        <React.Fragment>
          {currentTenants ? (
            <>
              <Helmet>
                <meta name="name" content={currentTenants.metaName}></meta>
                <meta
                  name="description"
                  content={t(currentTenants.metaDescription)!}
                />
              </Helmet>
              <Helmet>
                <script
                  async
                  src={`https://www.googletagmanager.com/gtag/js?id=${currentTenants.googleAnalyticsKey}`}
                ></script>
              </Helmet>
              <Helmet
                script={[
                  {
                    type: 'text/javascript',
                    innerHTML: `
                window.dataLayer = window.dataLayer || [];
                function gtag() {
                  dataLayer.push(arguments);
                }
                gtag('js', new Date());
                gtag('config', '${currentTenants.googleAnalyticsKey}');
                `
                  }
                ]}
              ></Helmet>
            </>
          ) : null}
          <BrowserView>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{ minHeight: '100vh' }}
            >
              <Grid item xs={3}>
                <Card
                  variant="outlined"
                  sx={{ maxWidth: 1000, height: 600, borderRadius: 10 }}
                >
                  <CardMedia
                    component="img"
                    alt="Image of a mobile phone"
                    height="400"
                    image="./preview16.jpg"
                  />
                  <CardContent>
                    <H2 marginLeft={1} onClick={() => navigate('/')}>
                      {t('mobile_only_notice')}
                    </H2>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </BrowserView>

          <MobileOnlyView>
            <Grid>
              <Grid container direction="row">
                <Grid
                  container
                  item
                  xs={6}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <H1 marginLeft={1} onClick={() => navigate('/')}>
                    <img
                      src={imageHelper.getLogo(currentTenants?.id!)}
                      alt={currentTenants?.name}
                      style={{ margin: 15 }}
                    />
                  </H1>
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  xs={6}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <button className="button" onClick={() => setOpenFirst(true)}>
                    <Icon name="menu" size="32" color={colorStore.colorC} />
                  </button>
                  <Modal
                    open={openFirst}
                    onClose={() => setOpenFirst(false)}
                    classNames={{ modal: 'menu' }}
                    closeIcon={modalCloseIcon}
                    styles={{
                      modal: {
                        backgroundColor: colorStore.colorB,
                        color: colorStore.colorC
                      }
                    }}
                  >
                    <Menu />
                  </Modal>
                </Grid>
              </Grid>
              {props.children}
              <Grid item xs={12} marginTop={8}>
                <Container>
                  <H2>{currentTenants?.name}</H2>
                  <H5 marginTop={2}>{t(currentTenants?.metaDescription!)}</H5>
                </Container>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                marginTop={8}
                paddingBottom={6}
              >
                <img
                  src={imageHelper.getLogo(currentTenants?.id!)}
                  alt={currentTenants?.name}
                  style={{ margin: 10 }}
                />
                <Grid item xs={8}>
                  <Container>
                    <H5>{t('layout.email') + ': ' + contacts?.email}</H5>
                    <H5>{t('layout.phone') + ': ' + contacts?.phone}</H5>
                  </Container>
                </Grid>
              </Grid>
            </Grid>
          </MobileOnlyView>

          <CookieConsent
            location="bottom"
            buttonText="OK"
            cookieName="cookieConsent"
            style={{
              backgroundColor: colorStore.colorC,
              color: colorStore.colorB
            }}
            buttonStyle={{
              fontSize: '13px',
              backgroundColor: colorStore.colorA,
              color: colorStore.colorC
            }}
            expires={150}
          >
            <div ref={cookieRef}></div>
          </CookieConsent>
        </React.Fragment>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export default observer(AppClientLayout);
